import React from 'react'
import { string, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getParam } from '#utils/getParam'
import UpdatedHomepage from './updated-homepage'

export const Home = () => {
  const { t } = useTranslation('home')
  const origin = getParam('origin')
  const secureStream = origin === 'securestream'

  if (secureStream) {
    return <div>{t('createYourFreeAccount')}</div>
  }

  return <UpdatedHomepage />
}

Home.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
}
