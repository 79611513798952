import React from 'react'
import { HomeNavigation } from '../_components/home-navigation'
import { DefaultWelcomeSection } from '../default-homepage/sections/default-welcome-section'
import { Footer } from '#components/footer'
import { useLogin } from '#hooks/useLogin'

export const LoginRequired = () => {
  const entryLink = useLogin(true)

  return (
    <>
      <HomeNavigation onButtonClick={entryLink} />
      <DefaultWelcomeSection />
      <Footer />
    </>
  )
}
