import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Icon = styled.div`
  margin-bottom: 3rem;
`

const IconImage = styled.img`
  max-width: 12rem;
`

const CtaButton = styled.button`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  border: none;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  border-radius: 0.375rem;
  font-size: 1rem;

  &:hover {
    background-color: white;
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

const Link = styled.a`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  &:hover {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

const IconLink = ({
  imagePath,
  buttonLink,
  buttonText,
  callback = () => {},
}) => {
  return (
    <Container>
      <Icon>
        <IconImage src={imagePath} alt="Fans Icon" />
      </Icon>
      {buttonLink ? (
        <Link href={buttonLink}>
          <CtaButton>{buttonText}</CtaButton>
        </Link>
      ) : (
        <CtaButton onClick={callback}>{buttonText}</CtaButton>
      )}
    </Container>
  )
}

export default IconLink
