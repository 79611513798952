import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  navigationWrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  homepageHeader: {
    display: 'flex',
    justifyContent: 'center',
    height: '64px',
    border: `solid 1px ${theme.palette.color.darkGrey}`,
    borderBottom: `1px solid ${theme.palette.color.primary}`,
    backgroundColor: theme.palette.color.charcoal,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    margin: 8,
    width: '100%',
  },
  icon: {
    width: '137px',
    height: '24px',
  },
  buttonIcon: {
    paddingRight: 5,
  },
  flex: {
    display: 'flex',
  },
}))

const isInLimitedMaintenance = window.LIMITED_MAINTENANCE

const SocialLinkGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SocialLink = styled.a`
  font-family: 'AvenirNextLTPro-Regular', Arial, serif;
  font-weight: bold;
  font-size: 0.6rem;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const HomeNavigation = () => {
  const classes = useStyles()
  const { t } = useTranslation('home')

  return (
    <div className={classes.navigationWrapper}>
      <div className={classes.homepageHeader}>
        <div className={classes.headerWrapper}>
          <img
            src="/assets/svg/ic-tunego-logo.svg"
            alt=""
            className={classes.icon}
          />
          <div className={`${isInLimitedMaintenance ? '' : classes.flex}`}>
            <SocialLinkGroup>
              <SocialLink href="https://discord.gg/vp9Z7xhpMN">
                <img
                  src="assets/images/updated-homepage/discord-mark-primary.svg"
                  alt="discord"
                  width={25}
                />
              </SocialLink>
              <SocialLink href="https://twitter.com/tunego?lang=en">
                <img
                  src="assets/svg/x-logo-primary.svg"
                  alt="x logo"
                  width={20}
                />
              </SocialLink>
            </SocialLinkGroup>
          </div>
        </div>
      </div>
    </div>
  )
}
