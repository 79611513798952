import React from 'react'
import mobile from 'is-mobile'
import styled from 'styled-components'
import { useMediaQuery } from '@material-ui/core'

const Container = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  max-width: 1480px;
  gap: 5rem;
  padding-top: 5rem;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 100%;
    gap: 5rem;
    max-width: 100%;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-width: 100%;
    gap: 0rem;
    max-width: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Title = styled.div`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  margin-bottom: 3rem;
  font-family: 'AvenirNextLTPro-Regular', Arial, serif;
  font-weight: bold;
`

const CtaButton = styled.button`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  border: none;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  border-radius: 0.375rem;
  font-size: 1rem;

  &:hover {
    background-color: white;
  }
`

const Link = styled.a`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
  text-decoration: none;

  &:hover {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: auto;
  margin-top: auto;
  @media (max-width: 1000px) {
    width: 80%;
    margin: auto;
  }
`

const InfoBlock = ({
  title,
  description,
  buttonText,
  buttonLink,
  imagePath,
  reverse = false,
}) => {
  const isMobile = mobile({ tablet: true })
  const isUnder1000 = useMediaQuery('(max-width: 1000px)')

  return (
    <Container>
      {(reverse || isMobile || isUnder1000) && (
        <Image src={imagePath} alt="Fans Icon" />
      )}
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Link href={buttonLink}>
          <CtaButton>{buttonText}</CtaButton>
        </Link>
      </TextContainer>
      {!reverse && !isMobile && !isUnder1000 && (
        <Image src={imagePath} alt="Fans Icon" />
      )}
    </Container>
  )
}

export default InfoBlock
