import React, { useState } from 'react'
import { string, func, arrayOf, bool, array } from 'prop-types'
import { useUploadManager } from '#modules/upload-manager/context'
import { Trans, useTranslation } from 'react-i18next'
import { SelectFile } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/select-file'
import styled from 'styled-components'
import { SelectSingle } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/select-single'
import { UploadedFileIndicator } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/uploaded-file-indicator'
import {
  DROPZONE_TYPE_ERROR,
  UPLOAD_STATUS,
} from '#pages/sell-collectibles/create-collectible/_components/steps/files/_constants'
import { CropImage } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/crop-image'
import { OverwriteFile } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/overwrite-file'
import { Error } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/error'

const SFiles = styled.div`
  padding: 0;
  margin: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const UploadModalContent = ({
  parentId,
  onUpload,
  accept,
  validator,
  close,
  isImage,
  uploadStatus,
  files,
  setFiles,
  setUploadStatus,
  onFileSelect,
  uploadedFileId,
  setUploadedFileId,
}) => {
  const { t } = useTranslation('nftWizard')

  const {
    onDrop,
    fileUploadError,
    validationError,
    uploadExistingFiles,
    alreadyExistingFiles,
    clearAlreadyExistingFiles,
    setIsOverwriteFilesModalOpen,
  } = useUploadManager()

  const [disableClose, setDisableClose] = useState(false)
  const [dropError, setDropError] = useState(null)
  const [projectId, setProjectId] = useState(null)

  const uploadError = validationError || fileUploadError || dropError

  const onUploadFiles = async (projectId, existing = false) => {
    try {
      setUploadStatus(UPLOAD_STATUS.PROCESSING_IN_PROGRESS)
      setProjectId(projectId)
      setDisableClose(true)

      const response = await (existing
        ? uploadExistingFiles({ projectId })
        : onDrop({
            filesToUpload: files,
            projectId,
          }))

      if (!response) {
        setDropError(t('steps.files.errorUploading'))
        return
      }

      if (response.successful?.length > 0) {
        await onUpload()

        const [data] = response.successful
        const id = data.meta.fileId

        onFileSelect({ id })
        setUploadedFileId(id)
      }
    } catch (err) {
      setDropError(t('steps.files.errorUploading'))
    } finally {
      setDisableClose(false)
    }
  }

  const onFinishProcessing = () => {
    close()
    setFiles([])
    setUploadedFileId(null)
    setProjectId(null)
    setUploadStatus(UPLOAD_STATUS.SELECT_FILE)
    setIsOverwriteFilesModalOpen(false)
    onUpload()
  }

  const onSelectFiles = files => {
    setDropError(null)
    setFiles(files)

    if (files?.length)
      setUploadStatus(
        isImage ? UPLOAD_STATUS.CROP_IMAGE : UPLOAD_STATUS.SELECT_SINGLE
      )
  }

  const onDropRejected = files => {
    if (!files?.length) return

    const [error] = files[0].errors

    const message =
      typeof error?.message === 'string' &&
      error.message.startsWith(DROPZONE_TYPE_ERROR) ? (
        <Trans ns="nftWizard" i18nKey="validation.invalidFormat">
          <b />
        </Trans>
      ) : (
        error.message
      )

    setDropError(message)
  }

  const updateSelectedFile = file => {
    // In this case, we are handling only single file upload
    // for multiple upload we need to update cropping functionality
    setFiles([file])
  }

  const resetProcess = () => {
    setIsOverwriteFilesModalOpen(false)
    setUploadStatus(UPLOAD_STATUS.SELECT_FILE)
    setFiles([])
    setUploadedFileId(null)
    setProjectId(null)
    clearAlreadyExistingFiles()
  }

  const resolveContent = () => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.SELECT_FILE:
        return (
          <SelectFile
            setFiles={onSelectFiles}
            accept={accept}
            validator={validator}
            onDropRejected={onDropRejected}
          />
        )
      case UPLOAD_STATUS.CROP_IMAGE:
        return (
          <CropImage
            file={files[0]}
            updateImage={updateSelectedFile}
            setUploadStatus={setUploadStatus}
          />
        )
      case UPLOAD_STATUS.SELECT_SINGLE:
        return (
          <>
            <SFiles>{files[0].name}</SFiles>
            <SelectSingle parentId={parentId} onUpload={onUploadFiles} />
          </>
        )
      case UPLOAD_STATUS.FILE_ALREADY_EXISTS:
        return (
          <OverwriteFile
            alreadyExistingFile={alreadyExistingFiles[0]}
            onCancel={resetProcess}
            onUpload={() => onUploadFiles(projectId, true)}
          />
        )
      case UPLOAD_STATUS.PROCESSING_IN_PROGRESS:
        return (
          <UploadedFileIndicator
            fileId={uploadedFileId}
            closeDisabled={disableClose}
            onFinishProcessing={onFinishProcessing}
            onClose={close}
          />
        )
    }
  }

  return (
    <>
      {resolveContent()}
      {uploadError && (
        <Error error={dropError ?? t('steps.files.uploadFailed')} />
      )}
    </>
  )
}

UploadModalContent.propTypes = {
  parentId: string.isRequired,
  onUpload: func,
  accept: arrayOf(string),
  isImage: bool,
  validator: func,
  close: func,
  setUploadStatus: func,
  onFileSelect: func,
  files: array,
  setFiles: func,
  uploadedFileId: string,
  setUploadedFileId: func,
}
