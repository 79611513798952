import React from 'react'
import styled from 'styled-components'
import WelcomeSection from './welcome-section'
import { useLogin } from '#hooks/useLogin'
import InfoSection from './info-section'
import AsFeaturedSection from './as-featured-section'
import Footer from './footer'
import { HomeNavigation } from './home-navigation'
import CreatorModal from './components/CreatorModal'

const Container = styled.div``

const UpdatedHomepage = () => {
  const signupRedirect = useLogin(true)
  return (
    <Container>
      <HomeNavigation onButtonClick={signupRedirect} />
      <WelcomeSection />
      <InfoSection />
      <AsFeaturedSection />
      <Footer />
    </Container>
  )
}

export default UpdatedHomepage
