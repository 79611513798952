import { Modal } from '#components/modal'
import { useLogin } from '#hooks/useLogin'
import React from 'react'

import styled from 'styled-components'

const SModal = styled(Modal)`
  width: 540px;
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CtaButton = styled.button`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  width: 200px;
  border: none;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  border-radius: 0.375rem;
  font-size: 1rem;

  &:hover {
    background-color: white;
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

const ItemImage = styled.img`
  width: 80px;
  margin-bottom: 1rem;
`

const Link = styled.a`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  &:hover {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

const CreatorModal = ({ isOpened, setIsOpened }) => {
  const login = useLogin()

  return (
    <SModal
      open={isOpened}
      header={false}
      closeBtn
      isOpen={isOpened}
      setIsOpen={setIsOpened}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <ModalContent>
        <Item>
          <ItemImage src="/assets/images/updated-homepage/newuser.png" />
          <Link href={'https://creators.tunego.com/'}>
            <CtaButton>New User</CtaButton>
          </Link>
        </Item>
        <Item>
          <ItemImage src="/assets/images/updated-homepage/existinguser.png" />

          <CtaButton onClick={login}>Existing User</CtaButton>
        </Item>
      </ModalContent>
    </SModal>
  )
}

export default CreatorModal
