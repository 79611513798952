import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-family: 'AvenirNextLTPro-bold', Arial, serif;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  width: fit-content;

  &:hover {
    a {
      color: ${({
        theme: {
          tunego: { COLORS },
        },
      }) => COLORS.primary_50};
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
    font-family: 'AvenirNextLTPro-bold', Arial, serif;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
  }
`

const FooterLink = ({ text, link }) => {
  return (
    <Container>
      <a href={link}>{text}</a>
    </Container>
  )
}

export default FooterLink
