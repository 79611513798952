import React, { createContext, useContext, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilesApi } from '#api/requests/files'
import { useError } from '#hooks/useError'
import { convertBytesToGb } from '#utils/convertBytes'
import { useUppyUpload } from '#modules/upload-manager/hooks/useUppyUpload'
import { MAX_FILE_SIZE } from '#modules/upload-manager/constants'
import { getFileInfo } from '#utils/getFileInfo'

export const ACTION_TYPES = {
  OPEN: 'OPEN_MANAGER',
  CLOSE: 'CLOSE_MANAGER',
  MINIMIZE: 'MINIMIZE_MANAGER',
  MAXIMIZE: 'MAXIMIZE_MANAGER',
  START_UPLOADING: 'START_UPLOADING',
  STOP_UPLOADING: 'STOP_UPLOADING',
  SET_FILES_IN_PROGRESS: 'SET_FILES_IN_PROGRESS',
}

// eslint-disable-next-line no-control-regex
const FILE_NAME_REGEX = /[^\u0000-\u00FF]/g

export const uploadManagerReducer = (state, action) => {
  const { type } = action

  switch (type) {
    case ACTION_TYPES.OPEN:
      return {
        ...state,
        isOpen: true,
      }
    case ACTION_TYPES.CLOSE: {
      return {
        ...state,
        isOpen: false,
      }
    }
    case ACTION_TYPES.MAXIMIZE:
      return {
        ...state,
        isMinimized: false,
      }
    case ACTION_TYPES.MINIMIZE: {
      return {
        ...state,
        isMinimized: true,
      }
    }
    case ACTION_TYPES.START_UPLOADING:
      return {
        ...state,
        isUploading: true,
      }
    case ACTION_TYPES.STOP_UPLOADING: {
      return {
        ...state,
        isUploading: false,
      }
    }
    default:
      return state
  }
}

export const initialState = {
  isOpen: false,
  isMinimized: false,
  isUploading: false,
}

const UploadManagerContext = createContext()

export const UploadManagerProvider = ({ children }) => {
  const { t } = useTranslation('validation')
  const [state, dispatch] = useReducer(uploadManagerReducer, initialState)
  const [alreadyExistingFiles, setAlreadyExistingFiles] = useState([])
  const [isOverwriteFilesModalOpen, setIsOverwriteFilesModalOpen] =
    useState(false)
  const { isMinimized, isOpen, isUploading } = state
  const { error, setResponseError, clearError } = useError()
  const {
    error: validationError,
    setResponseError: setValidationResponseError,
    setError: setValidationError,
    clearError: clearValidationError,
  } = useError()

  const openManager = () => dispatch({ type: ACTION_TYPES.OPEN })
  const closeManager = () => dispatch({ type: ACTION_TYPES.CLOSE })
  const maximizeManager = () => dispatch({ type: ACTION_TYPES.MAXIMIZE })
  const minimizeManager = () => dispatch({ type: ACTION_TYPES.MINIMIZE })
  const startUploading = () => dispatch({ type: ACTION_TYPES.START_UPLOADING })
  const stopUploading = () => dispatch({ type: ACTION_TYPES.STOP_UPLOADING })
  const clearAlreadyExistingFiles = () => {
    setAlreadyExistingFiles([])
  }
  const toggleManager = () =>
    isMinimized ? maximizeManager() : minimizeManager()

  const onRestoreUpload = () => {
    openManager()
    startUploading()
  }

  const { uppy, uploadingFilesInProgress, cancelAllUploads, cancelUpload } =
    useUppyUpload(onRestoreUpload, stopUploading)

  const checkIfFileValid = file => {
    const isTooLarge = file.size >= MAX_FILE_SIZE
    const isEmpty = file.size === 0
    const isNameInvalid = FILE_NAME_REGEX.test(file.name)

    const incorrectFile = isTooLarge || isEmpty || isNameInvalid

    if (incorrectFile) {
      const i18nKey = isTooLarge
        ? 'errorTooLarge'
        : isEmpty
        ? 'errorEmptyFile'
        : 'errorWrongChar'

      const errorMessage = t(i18nKey, {
        name: file.name,
        size: convertBytesToGb(MAX_FILE_SIZE).size,
      })

      setValidationError(errorMessage)
    }

    return !incorrectFile
  }

  const checkIfFileExists = async (file, projectId) => {
    try {
      const { data } = await FilesApi.checkForExistingFileName(
        projectId,
        file.name
      )

      if (data?.isFileExist) {
        setAlreadyExistingFiles(prev => [...prev, { file, ...data }])
        setIsOverwriteFilesModalOpen(true)

        return true
      }

      return false
    } catch (err) {
      setValidationResponseError(err)
    }
  }

  const clearFileFromUppy = async (file, projectId) => {
    try {
      const files = uppy.getFiles()

      const existingUppyUpload = files.find(existingFile => {
        return (
          existingFile.name === file.name &&
          existingFile.meta.structureId === projectId
        )
      })

      if (existingUppyUpload) await uppy.removeFile(existingUppyUpload.id)
    } catch (err) {
      setResponseError(err)
    }
  }

  const upload = async (file, projectId, index = 0) => {
    try {
      startUploading()
      await clearFileFromUppy(file, projectId)
      const { fileSuffix, name, category, fileSize } = getFileInfo(file)
      const { data } = await FilesApi.postFile({
        fileSuffix,
        fileSize: `${fileSize}`,
        name,
        category,
        structureId: projectId,
      })

      const generatedFileName =
        index === 0 ? file.name : `${file.name} (${index})`

      uppy.addFile({
        name: generatedFileName,
        path: file.path,
        type: file.type,
        data: file,
        meta: {
          fileId: data.id,
          structureId: projectId,
          fileSuffix,
          type: file.type,
        },
      })
    } catch (err) {
      if (err.message.startsWith('Cannot add the duplicate file')) {
        await upload(file, projectId, index + 1)
      } else {
        setResponseError(err)
      }
    }
  }
  const onDrop = async ({ projectId, filesToUpload }) => {
    try {
      await Promise.all(
        filesToUpload
          .filter(file => checkIfFileValid(file))
          .map(async file => {
            const fileAlreadyExists = await checkIfFileExists(file, projectId)

            if (fileAlreadyExists) return

            await upload(file, projectId)
          })
      )

      const { successful, failed } = await uppy.upload()

      return {
        successful,
        failed,
      }
    } catch (err) {
      setResponseError(err)
    }
  }

  const uploadExistingFiles = async ({ projectId, triggerMutate }) => {
    try {
      setIsOverwriteFilesModalOpen(false)

      await Promise.all(
        alreadyExistingFiles.map(async ({ file }) => {
          await upload(file, projectId)
        })
      )
      setAlreadyExistingFiles([])

      const data = await uppy.upload()

      if (triggerMutate) triggerMutate()

      return {
        successful: data?.successful || [],
        failed: data?.failed || [],
      }
    } catch (err) {
      setResponseError(err)
    }
  }

  const value = {
    isOpen,
    isUploading,
    isMinimized,
    uploadingFilesInProgress,
    alreadyExistingFiles,
    isOverwriteFilesModalOpen,
    setIsOverwriteFilesModalOpen,
    fileUploadError: error,
    validationError,
    onDrop,
    toggleManager,
    openManager,
    closeManager,
    maximizeManager,
    minimizeManager,
    uploadExistingFiles,
    clearAlreadyExistingFiles,
    clearFileUploadError: clearError,
    clearValidationError,
    cancelAllUploads,
    cancelUpload,
  }

  return (
    <UploadManagerContext.Provider {...{ value }}>
      {children}
    </UploadManagerContext.Provider>
  )
}

export const useUploadManager = () => {
  const context = useContext(UploadManagerContext)
  if (!context) {
    throw new Error(
      'useUploadManager must be used within UploadManagerProvider'
    )
  }
  return context
}
