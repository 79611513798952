import React from 'react'
import styled from 'styled-components'
import CompanyLogo from './components/CompanyLogo'

const Container = styled.div`
  margin: auto;
  text-align: center;
  padding-bottom: 15rem;
  max-width: 1280px;

  @media (max-width: 1280px) {
    padding-bottom: 3rem;
  }
`

const Title = styled.div`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: 2.25rem;
  line-height: 2.5rem;
  margin-bottom: 4rem;
`

const LogoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  margin-bottom: 5rem;
  gap: 4rem;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

const AsFeaturedSection = () => {
  return (
    <Container>
      <Title>As Featured In</Title>
      <LogoSection>
        <CompanyLogo logoPath="/assets/images/updated-homepage/billboard.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/forbes.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/bloomberg.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/hypebot.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/yahoo.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/venturebeat.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/cheddar.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/musically.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/musicbusinessworldwide.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/digitalmusicnews.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/musicconnection.png" />
        <CompanyLogo logoPath="/assets/images/updated-homepage/medium.png" />
      </LogoSection>
    </Container>
  )
}

export default AsFeaturedSection
