import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: auto;
  margin-top: auto;

  @media (max-width: 1400px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const CompanyLogo = ({ logoPath }) => {
  return (
    <Container>
      <Image src={logoPath} alt="Company Logo" />
    </Container>
  )
}

export default CompanyLogo
