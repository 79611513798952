import { config } from '@onflow/fcl'
//@ts-ignore
import { send as grpcSend } from '@onflow/transport-grpc'

const useGrpc = process.env.REACT_APP_USE_GRPC !== 'false';

if(useGrpc) {
  config()
    .put('sdk.transport', grpcSend);
}

config()
  .put('accessNode.api', process.env.REACT_APP_ACCESS_NODE)
  .put('discovery.wallet', process.env.REACT_APP_WALLET_DISCOVERY)
  .put('discovery.wallet.method', process.env.REACT_APP_WALLET_DISCOVERY_METHOD)
